import 'iframe-resizer/js/iframeResizer';

const iframePage = (function() {
    const init = function() {
        const isIframePage = document.getElementById('iframePage');

        if (!isIframePage) {
            return;
        }

        iFrameResize({ heightCalculationMethod: 'max' }, '#iframePage');
    };

    return {
        init: init
    };
})();

export default iframePage;