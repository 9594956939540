const $ = require('jquery');
window.jQuery = $;
require('bootstrap');

//Menu
import "./components/menu.js";

// Image lazy load
import { registerImageLoadEvents } from "./components/image-load.js";

//Header
import "./components/header.js";

//Truncating text
import "./components/shave.js";

//Top scroll
import "./components/topscroll.js";

//Top scroll
import "./components/glider.js";

//Country-picker
import "./components/country-picker.js";

//IframeResizer
import iframePage from "./pages/iframepage.js";

//Cookiebot-banner
import "./components/cookiebot-banner.js";

document.addEventListener('DOMContentLoaded', () => {
    registerImageLoadEvents();
    iframePage.init();
});

window.addEventListener('load', () => {
    var doc = document.body;
    doc.setAttribute('data-useragent', navigator.userAgent);
});


