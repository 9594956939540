var _ = require("underscore");

document.addEventListener('DOMContentLoaded', () => {
    $("#search-collapse").on("shown.bs.collapse",
        function() {
            $("#search-collapse input").focus();
        });

    $(".navbar-toggle").click(function () {
        $("#navbar-collapse").toggleClass("open");
        $(".navbar-toggle").toggleClass("open");
        if ($(".navbar-toggle").hasClass("open")) {
            $("body").addClass("no-scroll");
            window.onresize = function () {
                $(".navbar-collapse-container").css("max-height", window.innerHeight);
            }
            window.addEventListener("resize", window.onresize);
            window.onresize();
        }
        else {
            $("body").removeClass("no-scroll");
            $(".language-area").removeClass("open");
        }
        if ($("#navbar-collapse").hasClass("open")) {
            $(".menu-scroll").animate({ scrollTop: 0 }, "fast");
        }
    });

    $(".menu-overlay").click(function () {
        $("#navbar-collapse").removeClass("open");
        $(".navbar-toggle").removeClass("open");
        $("body").removeClass("no-scroll");
    });

    $(".search-toggle-mobile").click(function() {
        $("#search-mobile").toggleClass("hidden").toggleClass("shown");
        if ($("#search-mobile").hasClass("shown")) {
            $("#search-mobile input").focus();
            hideSearch();
        } else {
            $('body').off("click");
        }
    });

    function hideSearch() {
        $('body').click(function (e) {
            if (!$("#search-mobile").is(e.target) && $("#search-mobile").has(e.target).length === 0
                && !$(".search-toggle-mobile").is(e.target) && $(".search-toggle-mobile").has(e.target).length === 0) {
                $("#search-mobile").addClass("hidden").removeClass("shown");
                $('body').off("click");
            }
        });
    }

    window.addEventListener('resize', _.throttle(function () {
            if (window.innerWidth > 1249) {
                if (!$(".dropdown").hasClass("open") && !$("header").hasClass("language-menu-open")) {
                    $("body").removeClass("no-scroll");
                }
                else if ($(".language-menu-wrapper-mobile").hasClass("open")) {
                    $(".language-menu-wrapper-mobile").removeClass("open");
                }
            }
            else {
                $("#LanguageMenuContainer").css("height", 0).removeClass("open");
                $("header").removeClass("language-menu-open");
                if ($(".dropdown").hasClass("open")) {
                    $("body").addClass("no-scroll");
                    $(".navbar-toggle").addClass("open");
                    $(".navbar-collapse").addClass("open");
                    $(".navbar-collapse-container").addClass("no-scroll");
                }
                else if (!$(".navbar-collapse").hasClass("open")) {
                    $("body").removeClass("no-scroll");
                }
                else if ($(".navbar-collapse").hasClass("open")) {
                    $("body").addClass("no-scroll");
                }
            }
        }, 250));

    if ($("#search-mobile input").val() !== "" && $(window).width() < 992) {
        $("#search-mobile").toggleClass("hidden").toggleClass("shown");
        $("#search-mobile input").focus();
        hideSearch();
    }

    $('.column-content h4').html(function () {
        var text = $(this).text().split(' ');
        var last = text.pop();
        return text.join(" ") + (text.length > 0 ? ' <span class="last">' + last + '</span>' : last);
    });
});