import shave from "shave";

document.addEventListener('DOMContentLoaded', () => {
    var teaserBlock = $(".teaser-block .text .trunc");
    var newsListItem = $(".news-list-item .text");
    var topArticle = $(".top-article .text .trunc");
    let timerTeaser = null;
    let timerList = null;
    let timerArticle = null;

    shave(teaserBlock, getHeight(teaserBlock), { character: " ..." });
    shave(newsListItem, getHeight(newsListItem), { character: " ..." });
    shave(topArticle, getHeight(topArticle), { character: " ..." });

    $(window).on('resize', function () {
        updateShave(teaserBlock, timerTeaser);
        updateShave(newsListItem, timerList);
        updateShave(topArticle, timerArticle);
    });

    $(window).resize();
});

function getHeight(element) {
    if (element.css("max-height") !== undefined && element.css("max-height") !== "none") {
        return parseInt(element.css("max-height"));
    }
    else {
        return 1000;
    }
}

function updateShave(element, timer) {
    var el = $(element);
    var text = el.textContent;
    clearTimeout(timer);
    timer = setTimeout(function () {
        // add the original text
        el = text;
        shave(element, getHeight(element), { character: " ..." });
    }, 200);
}