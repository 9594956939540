let lazyImages = [];
let active = false;
const distanceOutsideScreen = 400;

function loadImages(lazyImage) {
    if (lazyImage.dataset.srcset) {
        lazyImage.srcset = lazyImage.dataset.srcset;
    } else if (lazyImage.dataset.src) {
        lazyImage.src = lazyImage.dataset.src;
    }
    lazyImage.classList.remove("lazy");

    lazyImages = lazyImages.filter(function (image) {
        return image !== lazyImage;     
    });

    if (lazyImages.length === 0) {
        document.removeEventListener("scroll", lazyLoad);
        window.removeEventListener("resize", lazyLoad);
        window.removeEventListener("orientationchange", lazyLoad);
    }
}

function lazyLoad() {
    if (active) {
        return;
    }
    active = true;

    setTimeout(function () {
        lazyImages.forEach(function (lazyImage) {

            if (((lazyImage.getBoundingClientRect().top < 0) || (lazyImage.getBoundingClientRect().top <= window.innerHeight + distanceOutsideScreen && lazyImage.getBoundingClientRect().bottom >= 0)) && getComputedStyle(lazyImage).display !== "none") {
                loadImages(lazyImage);
            }
        });

        active = false;
    }, 200);

}

function registerImageLoadEvents() {
    lazyImages = [].slice.call(document.querySelectorAll(".lazy"));
    lazyLoad();
    document.addEventListener("scroll", lazyLoad);
    window.addEventListener("resize", lazyLoad);
    window.addEventListener("orientationchange", lazyLoad);
}

export { loadImages, registerImageLoadEvents };