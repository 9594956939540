window.addEventListener('CookiebotOnConsentReady', function (e) {
    if ( window.location.pathname == '/') {
        if (getCookie("country-picker-cookie") == null) {
            $('#countryPicker').modal('show');
        }
    }
}, false);


document.addEventListener('DOMContentLoaded', () => {
    
    $("#LanguageMenuToggle").click(function (e) {
        e.preventDefault()
        $('#countryPicker').modal('show');
        $("#countryPicker").addClass("modal-dialog-centered");
    });

    $("#LanguageMenuToggleMobile").click(function (e) {
        e.preventDefault()
        $('#countryPicker').modal('show')
        $("#navbar-collapse").removeClass("open");
        $("#navbar-toggle").removeClass("open");
    });

    $(".language-area").click(function (e) {
        $(this).toggleClass("open");
        if (window.innerWidth > 1249) {
            const areaId = $(this).attr("href");
            const LanguageHeight = $(".language-area-list").outerHeight(true) + $(".navbar-header").outerHeight() + $(areaId).outerHeight() + "px";
            $("#LanguageMenuContainer").css("height", LanguageHeight);
        }
    });

    $(".language-menu-return").click(function (e) {
        e.preventDefault()
        $("#LanguageMenuContainerMobile").removeClass("open");
        $("#LanguageMenuToggleMobile").removeClass("open");
    });
    var countryLinks = document.getElementsByClassName("country-link");
    for (var country of countryLinks) {
        country.addEventListener('click',
            function (e) {
                if (getCookie("country-picker-cookie") != $(this).attr("href")) {
                    document.cookie = "country-picker-cookie=" + $(this).attr("href") + " ; max-age =" + 365 * 24 * 60 * 60 + "; path=/";
                }
            });
    }

    if (window.location.pathname == '/' && getCookie("country-picker-cookie") != null && getCookie("country-picker-cookie") != "/") {
        window.location.href = getCookie("country-picker-cookie");

    }
});

function getCookie(cookie) {
    var cookieArr = document.cookie.split(";");
    for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        if (cookie == cookiePair[0].trim()) {
            return decodeURIComponent(cookiePair[1]);
        }
    }
    return null;
}

