import Glider from 'glider-js'; import '../../node_modules/glider-js/glider-compat.min.js';

window.addEventListener('load', function () {
    var linkCarousels = document.getElementsByClassName('link-carousel');
    var generalCarousels = document.getElementsByClassName('general-carousel');

    for (var i = 0; i < linkCarousels.length; i++) {
        var prevLink = linkCarousels[i].parentElement.querySelector('.glider-prev');
        var nextLink = linkCarousels[i].parentElement.querySelector('.glider-next');

        new Glider(linkCarousels[i], {
            slidesToShow: 1,
            slidesToScroll: 1,
            skipTrack: true,
            draggable: true,
            scrollLock: true,
            arrows: {
                prev: prevLink,
                next: nextLink
            },
            responsive: [
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2
                    }
                }, {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3
                    }
                }
            ]
        });
    }


    for (var j = 0; j < generalCarousels.length; j++) {
        var prevGeneral = generalCarousels[j].parentElement.querySelector('.glider-prev');
        var nextGeneral = generalCarousels[j].parentElement.querySelector('.glider-next');

        new Glider(generalCarousels[j], {
            slidesToShow: 1,
            slidesToScroll: 1,
            skipTrack: true,
            draggable: true,
            scrollLock: true,
            arrows: {
                prev: prevGeneral,
                next: nextGeneral
            }
        });
    }
});