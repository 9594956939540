var _ = require("underscore");

document.addEventListener('DOMContentLoaded', () => {
    //If page is not productpage
        $(window).on('scroll', _.throttle(function () {
            var scrollTop = $(window).scrollTop();
            if ($(window).width() > 991) {
                if (!$(".productpage-navigation").length && !$(".header-partspage").length) {
                    $('.vad-navbar').addClass('fixed-top');
                    
                    if (scrollTop > 300) {
                        $('.vad-navbar').addClass('sticky-navbar');
                    } else {
                        $('.vad-navbar').removeClass('sticky-navbar');
                    }
                }
            } else {
                if (scrollTop > 0) {
                    $('.vad-navbar').addClass('fixed-top');
                } else {
                    $('.vad-navbar').removeClass('fixed-top');
                }
            }
        }, 200));

  $(".main-menu .dropdown").click(function () {
      let imageElements = $(this).find('.menuImage');
      $.each(imageElements, function (index, value) {
        let src = $(this).attr('data-src');
        if ($(this).attr(src) !== "") {
          $(this).attr('src', src);
        }
      });
    });

    $('.main-menu .dropdown').on('show.bs.dropdown', () => {
        if ($(window).width() > 991) {
            $('body').addClass('no-scroll');
        } else {
            $('.navbar-collapse-container').addClass('no-scroll');
        }
    });

  $('.main-menu .dropdown').on('hide.bs.dropdown', () => {
        if ($(window).width() > 991) {
            if (!$('#LanguageMenuContainer').hasClass('open')) {
                $('body').removeClass('no-scroll');
            }
        } else {
            $('.navbar-collapse-container').removeClass('no-scroll');
        }
    });

    $('.language-menu a').on('click', () => {
        if ($(window).width() > 991) {
            $('body').removeClass('no-scroll');
        }
    });
});
