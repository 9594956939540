document.addEventListener('DOMContentLoaded', () => {

    const topScrollLink = document.querySelector(".top-scroll");
    const windowHeight = window.innerHeight;
    const scrollThreshold = 1.5 * windowHeight;

    window.addEventListener("scroll", () => {
        if (topScrollLink != null) {
            if (window.scrollY > scrollThreshold) {
                if (window.innerWidth < 991) {
                    topScrollLink.classList.add('hidden');
                }
                else {
                    topScrollLink.classList.remove('hidden');
                }
                topScrollLink.classList.add("active");
            } else {
                topScrollLink.classList.remove("active");
            }
        }
        
        
});
    const topScroll = document.querySelector("#topScroll");
    if (topScroll != null) {
        topScroll.addEventListener("click", event => {
            event.preventDefault();
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });
            topScrollLink.classList.remove("active");
        });
    }
    
});